const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript(`/scripts/cache.js?t=${Date.now()}`)
  }
}
